/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "@matheo/datepicker/theming" as datepicker;

@import "@angular/material/theming";
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
// $primary: mat-palette((50 : #1f86d1));
// $accent: mat-palette((50 : #1fd169));

// The "warn" palette is optional and defaults to red if not specified.
// $warn: mat.define-palette('yellow');
// $danger: mat.define-palette('red');

// mat.$theme-ignore-duplication-warnings: true;
@include mat.core();
// @include mat.all-component-themes($my-app-theme);

//////////////////////////////////////// COLORS //////////////////////////////////
// Global CSS variables => example: background-color: var(--accent);
:root {
  --primary: #05a1c6;
  --secondary: #add8e6;
  --accent: #233452; // #F56E28
  --warn: #ffff8c;
  --danger: #f10d2b;
}

.bg-primary {
  background-color: var(--primary) !important;
  color: white !important;
}

.color-primary {
  color: var(--primary) !important;
}

.color-secondary {
  color: var(--secondary) !important;
}
.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-accent {
  background-color: var(--accent) !important;
  color: white !important;
}

.color-accent {
  color: var(--accent) !important;
}

.bg-danger {
  background-color: var(--danger) !important;
}

.color-danger {
  color: var(--danger) !important;
}

.bg-white {
  background-color: white !important;
}

.color-white {
  color: white !important;
}

$mat-myapp-primary: (
  50: var(--primary),
  100: var(--primary),
  200: var(--primary),
  300: var(--primary),
  400: var(--primary),
  500: var(--primary),
  600: var(--primary),
  700: var(--primary),
  800: var(--primary),
  900: var(--primary),
  A100: var(--primary),
  A200: var(--primary),
  A400: var(--primary),
  A700: var(--primary),
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-myapp-accent: (
  50: var(--accent),
  100: var(--accent),
  200: var(--accent),
  300: var(--accent),
  400: var(--accent),
  500: var(--accent),
  600: var(--accent),
  700: var(--accent),
  800: var(--accent),
  900: var(--accent),
  A100: var(--accent),
  A200: var(--accent),
  A400: var(--accent),
  A700: var(--accent),
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-myapp-warn: (
  50: #ff3d00,
  100: #ff3d00,
  200: #ff3d00,
  300: #ff3d00,
  400: #ff3d00,
  500: #ff3d00,
  600: #ff3d00,
  700: #ff3d00,
  800: #ff3d00,
  900: #ff3d00,
  A100: #ff3d00,
  A200: #ff3d00,
  A400: #ff3d00,
  A700: #ff3d00,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// $primary: mat-palette($mat-myapp-primary);
// $accent: mat-palette($mat-myapp-accent);

// $theme: mat-light-theme($primary, $accent);

// @include angular-material-theme($theme);

// .pink-theme {
//   $pink-theme-primary: mat.define-palette(mat.$pink-palette, 400);
//   $pink-theme-accent: mat.define-palette(mat.$pink-palette, 400);

//   $pink-theme: mat.define-light-theme($pink-theme-primary, $pink-theme-accent);

//   @include mat.all-component-themes($pink-theme);
// }

$my-app-primary: mat-palette($mat-myapp-primary);
$my-app-accent: mat-palette($mat-myapp-accent);
$my-app-warn: mat-palette($mat-myapp-warn);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);
// mat.$theme-ignore-duplication-warnings: true;
// .mat-calendar{
//   @include datepicker.theme($my-app-theme);
// }

//////////////////////////////////////// FONTS //////////////////////////////////
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

.title {
  font: var(--text-title) !important;
}

.subtitle {
  font: var(--text-subtitle) !important;
}

.text-sm {
  font: var(--text-sm) !important;
}

*,
html,
body {
  font-size: 16px;
  font-weight: normal;
  font-family: "Inter", "sans-serif";
}

body {
  margin: 0;
}

:root {
  --text-sm: normal 14px "Inter", sans-serif;
  --text-subtitle: bold 24px "Inter", sans-serif;
  --text-title: bold 50px "Inter", sans-serif;
}

//////////////////////////////////////// OVERRIDE MATERIAL DESIGN /////////////////////////////////
// override default angular material
@mixin mix-app-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .mat-button-toggle-group {
    border: none;
  }

  .mat-button-toggle {
    background-color: mat-color($primary);
    color: mat-color($primary, default-contrast) !important;
    margin-left: 1rem;
    border-radius: 4px;
    width: 10rem;
  }
  // .horizontal .mat-button-toggle {
  //   width: 10rem;
  // }

  .mat-button-toggle-checked {
    background-color: mat-color($accent);
    color: mat-color($accent, default-contrast) !important;
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  .mat-tab-label .mat-tab-label-content {
    font: var(--text-subtitle);
  }

  mat-form-field label {
    font-size: 20px;
  }

  .borderless-select {
    &.mat-form-field-appearance-outline .mat-form-field-outline {
      opacity: 0;
      border-style: none;
    }

    &.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-start {
      opacity: 0;
      border-style: none;
    }

    &.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-end {
      opacity: 0;
      border-style: none;
    }
  }

  .mat-custom-time-hour {
    font-size: inherit !important;
  }
}

.borderless-select .mat-select-arrow {
  border: none !important;
}

// Apply the ovveride function
@include mix-app-theme($my-app-theme);

// change all material font to INTER
$mdc-typography-styles-headline1: (
  font-family: unquote("Inter"),
  font-size: 93,
);
$mdc-typography-styles-headline2: (
  font-family: unquote("Inter"),
  font-size: 58,
);
$mdc-typography-styles-headline3: (
  font-family: unquote("Inter"),
  font-size: 47,
);
$mdc-typography-styles-headline4: (
  font-family: unquote("Inter"),
  font-size: 33,
);
$mdc-typography-styles-headline5: (
  font-family: unquote("Inter"),
  font-size: 23,
);
$mdc-typography-styles-headline6: (
  font-family: unquote("Inter"),
  font-size: 19,
);
$mdc-typography-styles-body1: (
  font-family: unquote("Inter"),
  font-size: 16,
);
$mdc-typography-styles-body2: (
  font-family: unquote("Inter"),
  font-size: 14,
);
$mdc-typography-styles-subtitle1: (
  font-family: unquote("Inter"),
  font-size: 16,
);
$mdc-typography-styles-subtitle2: (
  font-family: unquote("Inter"),
  font-size: 14,
);
$mdc-typography-styles-button: (
  font-family: unquote("Inter"),
  font-size: 14,
);
$mdc-typography-styles-overline: (
  font-family: unquote("Inter"),
  font-size: 10,
);
$mdc-typography-styles-caption: (
  font-family: unquote("Inter"),
  font-size: 12,
);

// .orange-theme {

//   $orange-theme-primary: mat-palette($mat-deep-orange, 400);
//   $orange-theme-accent: mat-palette($mat-grey, 400);
//   $orange-theme-warn: mat-palette($mat-grey, 400);
//   // $orange-theme-accent: mat-palette(#424242, 400);

//   $orange-theme: mat-light-theme($orange-theme-primary, $orange-theme-accent);

//   @include angular-material-theme($orange-theme);
// }

//  @import '@angular/material/theming';
// @include mat-core();
// $my-app-primary: mat-palette($mat-blue-grey);
// $my-app-accent:  mat-palette($mat-pink, 500, 900, A100);
// $my-app-warn:    mat-palette($mat-deep-orange);
// $my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
// @include angular-material-theme($my-app-theme);

html,
body {
  height: 100%;
}

// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }

.bg-black {
  background-color: black;
  color: white;
}

.disabled {
  opacity: 0.5;
}

.bg-color {
  background-color: var(--class-accent-bg);
}

.bg-success {
  background-color: rgb(97, 231, 141);
}

.bg-error {
  background-color: rgb(231, 97, 97);
}

.bg-warn {
  background-color: rgb(231, 175, 97);
}

.bg-info {
  background-color: rgb(97, 175, 231);
}

///////////////////// SNACKBAR ////////////////////////
.action-btn-bottom {
  .mat-simple-snackbar {
    flex-direction: column;
    gap: 1rem;
  }
}

.snackbar{
  .mat-simple-snackbar {
    flex-direction: column;
    gap: 1rem;
    color: black;
  }
}

.info-snackbar {
  background: var(--secondary) !important; //#e9faff;
  color: rgba(0, 0, 0, 0.87);
  border: 2px solid #1ca9cb;
  button{
    color: #1ca9cb;
  }
}

.success-snackbar {
  background-color: #edfff2;
  border: 2px solid #00bb6d;
  button{
    color: #00bb6d;
  }
}

@media only screen and (max-width: 992px) {
  // global center for icons
  mat-icon {
    // display: flex !important;
    // justify-content: center;
    // align-items: center;
    font-size: 1.5rem;
  }

  .flex-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}


/* Hide the default scrollbar */
::-webkit-scrollbar {
  width: 0.5em;
  background-color: #f5f5f500;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
  border-radius: 10px;
}

/* Style the scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


// LIKE TAILWIND
// MARGIN
.mb-2 {
  margin-bottom: 0.5rem!important;
}

.ml-4 {
  margin-left: 1rem!important;
}
.mr-4 {
  margin-right: 1rem!important;
}
.m-4 {
  margin: 1rem!important;
}

// PADDING
.pl-4 {
  padding-left: 1rem!important;
}
.pr-4 {
  padding-right: 1rem!important;
}

.pb-4{
  padding-bottom: 1rem;
}
.p-4 {
  padding: 1rem!important;
}

